import React from 'react';
import { TailSpin } from 'react-loader-spinner';

function ConectandoseAnimation(props) {
  const spinning = props.state;
  return (
  <>
  <div  style={{position: "absolute", marginTop: "70px", left: "49%",}}>
  <div className="vstack gap-5">
  <TailSpin
  height="30"
  width="30"
  color="rgb(0,60,125,1)"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  visible={spinning}
/>
{ spinning && <h3 className="loading" style={{marginLeft: "-50px", color: "rgb(0,60,125,1)", fontSize: "20px"}}>Conectandose</h3> }
  </div>
  </div>
  <div
    style={{
      opacity: spinning > 0 ? 0.05 : 1,
      pointerEvents: spinning > 0 ? 'none' : 'auto',
    }}>
    { props.children}
  </div>
  </>
  )
}

export default ConectandoseAnimation;