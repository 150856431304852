import { endpointDomain } from "../config/configapp";
import { endpointPath } from "./endpointPath";

function checkUserToken() {
  const userToken = localStorage.getItem('user-token');
  if (!userToken || userToken === 'undefined') {
      // setIsLoggedIn(false);
      return false;
  }
  return userToken;
}

function logoutUserToken() {
  localStorage.clear();
  // window.location.reload();
  window.location.replace("/")
  return true;
}

function logoutUserTokenNoRefresh() {
  localStorage.clear();
  return true;
}

export function setSliceToken(year, token) {
  localStorage.setItem(year + '-token', token);
}

export function checkSliceToken(year) {
  const sliceToken = localStorage.getItem(year + '-token');
  if (!sliceToken || sliceToken === 'undefined') {
      return false;
  }
  return sliceToken;
}

export function setFilterData(filters) {
  localStorage.setItem('filters', JSON.stringify(filters));
}

export function checkFilterData() {
  const filterCookie = JSON.parse(localStorage.getItem('filters'));
  if (!filterCookie || filterCookie === 'undefined') {
      return false;
  }
  return filterCookie;
}

// function getAuthToken(){
//   const cookie = new Cookies()
//   let user = cookie.get('user')
//   if (user && user.token){
//     return user.token
//   } else {
//     return ''
//   }
// }

// function getUserId(){
//   const cookie = new Cookies()
//   let user = cookie.get('user')
//   if (user && user.id){
//     return user.id
//   } else {
//     return ''
//   }
// }


function mainGet(apiUrl, url){
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('token', checkUserToken())

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }
    
  return fetch(`${apiUrl}${url}`, requestOptions)
    .then(response => {
      if(response.status == 401){
        logoutUserToken()
      }
      else if(response.status == 403){
        return false
      }
      else {
        return response
      }
    })
    .then(response => response.text())
    .then(result => JSON.parse(result))
    .catch(error => console.log('error', error))
}

function mainPost(apiUrl, url, data){
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('token', checkUserToken())

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({...data}),
    redirect: 'follow',
  }
    
  return fetch(`${apiUrl}${url}`, requestOptions) 
    .then(response => {
      if(response.status < 300){
        return response.json().then(data => ({
          status: response.status,
          data
        }))
      } else {
        if(response.status == 401){
          logoutUserToken()
        }
        return response.text().then(data => ({
          status: response.status,
          data
        }))
      }}
    )
    .catch(error => console.log('error', error))
}

// function mainPostFileResponse(apiUrl, url, data){
//   var myHeaders = new Headers()
//   myHeaders.append('Accept', 'application/json')
//   myHeaders.append('Content-Type', 'application/json')
//   myHeaders.append('token', getAuthToken())
//   var user_id = getUserId()


//   var requestOptions = {
//     method: 'POST',
//     headers: myHeaders,
//     body: JSON.stringify({...data, user_id}),
//     redirect: 'follow'
//   }
    
//   return fetch(`${apiUrl}${url}`, requestOptions) 
//     .then(response => {
//       if(response.status < 300){
//         return response.blob().then(data => ({
//           status: response.status,
//           data
//         }))
//       } else {
//         if(response.status == 401){
//           deAuth()
//         }
//         return response.text().then(data => ({
//           status: response.status,
//           data
//         }))
//       }}
//     )
//     .catch(error => console.log('error', error))
// }

function mainPut(apiUrl, url, data){
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('token', checkUserToken())
    
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
  }
    
  return fetch(`${apiUrl}${url}`, requestOptions) 
    .then(response => {
      if(response.status < 300){
        return response.json().then(data => ({
          status: response.status,
          data
        }))
      } else {
        if(response.status == 401){
          logoutUserToken()
        }
        return response.text().then(data => ({
          status: response.status,
          data
        }))
      }}
    )
    .catch(error => console.log('error', error))
}



// function mainDelete(apiUrl, url){
//   var myHeaders = new Headers()
//   myHeaders.append('Accept', 'application/json')
//   myHeaders.append('Content-Type', 'application/json')
//   myHeaders.append('token', getAuthToken())

//   var requestOptions = {
//     method: 'DELETE',
//     headers: myHeaders,
//     redirect: 'follow'
//   }
    
//   return fetch(`${apiUrl}${url}`, requestOptions)
//     .then(response => response.text())
//     .then(result => JSON.parse(result))
//     .catch(error => console.log('error', error))
// }



export function apiLogin(data) {
  return mainPost(endpointDomain, endpointPath.Login, data)
}

export function apiCheckUserLogged() {
  return checkUserToken()
}

export function apiLogout() {
  return logoutUserToken()
}

export function apiLogoutNoRefresh() {
  return logoutUserTokenNoRefresh()
}

export function apiGetPlaceholder(){
  const data = mainGet(endpointDomain, endpointPath.placeholderTest)
  return data
}

// config_data
export function apiGetExtraFiltros(){
  const data = mainGet(endpointDomain, endpointPath.getExtraFiltros)
  return data
}

export function apiSetExtraFiltros(idempresaChoice,idproyectoChoice,cuentasChoice,divkeyChoice){
  let api_dir_subs = endpointPath.setExtraFiltros;

  if ( idempresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(idempresaChoice.join(':')).concat("&")
  }
  if ( idproyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(idproyectoChoice.join(':')).concat("&")
  }
  if ( cuentasChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentasChoice.join(':')).concat("&")
  }
  if ( divkeyChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('d=').concat(divkeyChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiSwitchACPExtraFiltros() {
  const data = mainGet(endpointDomain, endpointPath.switchACPExtraFiltros)
  return data
}

// user data
export function apiGetUserData(){
  const data = mainGet(endpointDomain, endpointPath.userData)
  return data
}

export function apiGetUserList(){
  const data = mainGet(endpointDomain, endpointPath.userList)
  return data
}

export function apiGetUserSearchdata(){
  const data = mainGet(endpointDomain, endpointPath.userSearchdata)
  return data
}

export function apiPutUserDelete(id){
  let api_dir_subs = endpointPath.userDelete.replace('{id}',id);
  const data = mainPut(endpointDomain, api_dir_subs)
  return data
}

export function apiPutUserPermisos(id,permisos){
  let api_dir_subs = endpointPath.userPermisos
                                            .replace('{id}',id);
                                            // .replace('{data_list}',permisos);
  if ( permisos.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('data_list=').concat(permisos)
  }                                          
  const data = mainPut(endpointDomain, api_dir_subs)
  return data
}

export function apiPutUserChangePass(id,pass){
  let api_dir_subs = endpointPath.userChangePass
                                            .replace('{id}',id)
                                            .replace('{new_password}',pass);                                      
  const data = mainPut(endpointDomain, api_dir_subs)
  return data
}

export function apiPutUserChangeRol(id,rol){
  let api_dir_subs = endpointPath.userChangeRol
                                            .replace('{id}',id)
                                            .replace('{new_user_type}',rol);                                      
  const data = mainPut(endpointDomain, api_dir_subs)
  return data
}

export function apiPutUserConfigViews(id,views){
  let api_dir_subs = endpointPath.userConfigViews
                                            .replace('{id}',id)
                                            .replace('{new_visualizaciones}',views);                                      
  const data = mainPut(endpointDomain, api_dir_subs)
  return data
}

export function apiPostUserCreate(data) {
  return mainPost(endpointDomain, endpointPath.userCreate, data)
}

// mapaspageSize, page
export function apiGetListFiltersOLD(selector, periodo){
  const data = mainGet(endpointDomain, endpointPath.filter + '/' + selector + '/' + periodo)
  return data
}

export function apiGetListFilters(selector){
  const data = mainGet(endpointDomain, endpointPath.filterSelectDataList.replace('{tipo_filtro}',selector))
  return data
}

export function apiGetListFiltersDatalistFilter(datalist, selector){
  let api_dir_subs = endpointPath.filterDatalistFilter
                                      .replace('{dataset}',datalist)
                                      .replace('{filter}',selector);

  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetSliceToken(year,filter){
  let api_dir_subs = endpointPath.sliceToken.replace('year',year);

  if ( filter.idempresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(filter.idempresaChoice.join(':')).concat("&")
  }
  if ( filter.idproyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(filter.idproyectoChoice.join(':')).concat("&")
  }
  if ( filter.cuentasChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(filter.cuentasChoice.join(':')).concat("&")
  }
  if ( filter.divkeyChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('d=').concat(filter.divkeyChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetChartMargenes(token, empresaChoice, proyectoChoice) {
    let api_dir_subs = endpointPath.chartMargenes.replace('redis_key',token)

    if ( empresaChoice.length > 0 ) {
      api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
    }
    if ( proyectoChoice.length > 0 ) {
      api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
    }  
    
    const data = mainGet(endpointDomain, api_dir_subs)
    return data
}

export function apiGetChartTodosYear(periodo, empresaChoice, proyectoChoice) {
  let api_dir_subs = endpointPath.chartTodosYear.replace('{idperiodo}',periodo)

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }  

  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetSliceData(nv2Choice,periodo,empresaChoice,proyectoChoice,hashExcel = []){

  let api_dir_subs = endpointPath.sliceData
                                      .replace('{redis_key}','token')
                                      .replace('{cuentaNV2}',nv2Choice.join('|'))
                                      .replace('{periodo}',periodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( hashExcel.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('extra_dataset=').concat(hashExcel).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetNv2Cuentas(filter,selNv2,empresaChoice,proyectoChoice,participacion = false){
  let api_dir_subs = endpointPath.cuentasNv2
                                      // .replace('{redis_key}',checkSliceToken(filter.yearChoice[0]) || filter.yearChoice[0])
                                      .replace('{cuentaNV3}',Object.keys(selNv2).join('|'))
                                      .replace('{periodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( participacion ) {
    api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion)
  }
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetEerrCuentasDetalle(idperiodo,cuenta,empresaChoice,proyectoChoice){
  // /balance_detalle/{idperiodo}/{ctacodigo}'
  let api_dir_subs = endpointPath.eerrCuentasDetalle
                        .replace('{idperiodo}',cuenta.join(':'))
                        .replace('{ctacodigo}',idperiodo)
  
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data                      
}


export function apiGetSubCuentas(idperiodo,cuenta,empresaChoice,proyectoChoice){
  // /balance_detalle/{idperiodo}/{ctacodigo}'
  let api_dir_subs = endpointPath.cuentasSub
                        .replace('{idperiodo}',cuenta.join(':'))
                        .replace('{ctacodigo}',idperiodo)
  
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data                      
}


export function apiGetDirectorioData(year,periodo,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.sliceDirectorioData
                                      .replace('{redis_key}','token')
                                      .replace('{year}',year)
                                      .replace('{periodo}',periodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetCuentasDirectorio(filter,selNv2,empresaChoice,proyectoChoice){

  let api_dir_subs = endpointPath.cuentasDirectorio
                                      .replace('{redis_key}',checkSliceToken(filter.yearChoice[0]) || filter.yearChoice[0])
                                      .replace('{cuentaNV3}',Object.keys(selNv2).join('|'))
                                      .replace('{year}',filter.yearChoice[0])
                                      .replace('{periodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetBalanceGeneral(year,periodo,empresaChoice,proyectoChoice, hashExcel = []){
  let api_dir_subs = endpointPath.balanceGeneral
                                      .replace('{cuenta_nv2}','ACTIVO')
                                      .replace('{year}',year)
                                      .replace('{ultimo_periodo}',periodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( hashExcel.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('extra_dataset=').concat(hashExcel).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetBalanceGeneralCuentas(filter,selNv4,empresaChoice,proyectoChoice){

  let api_dir_subs = endpointPath.balanceGeneralCuentas
                                      .replace('{cuentaNV4}',Object.keys(selNv4).join('|'))
                                      .replace('{year}',filter.yearChoice[0])
                                      .replace('{ultimo_periodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

// Inventario

export function apiGetInventarioDiario(idperiodo,cuenta,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.InventarioDiario
                        .replace('{idperiodo}',idperiodo.join(':'))
                        .replace('{ctacodigo}',cuenta)
  
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data                      
}

export function apiGetInventarioMensual(idperiodo,cuenta,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.InventarioMensual
                        .replace('{idperiodo}',idperiodo.join(':'))
                        .replace('{ctacodigo}',cuenta)
  
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data                      
}

// IMO

export function apiGetImoData(year,idperiodo,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.imoData
                                      .replace('{year}',year)
                                      .replace('{periodo}',idperiodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetImoCuentas(filter,imoNv2,imoNv3,empresaChoice,proyectoChoice){
  // "/imo/resultado_cuentas_clasificado/{idperiodo}/{imoNv3}/filter?"

  let api_dir_subs = endpointPath.imoCuentas
                                      .replace('{imoNv2}', encodeURIComponent(imoNv2))
                                      .replace('{imoNv3}', encodeURIComponent(Object.keys(imoNv3).join('|')))
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetImoCuentaDetalle(idperiodo,imoNv2,cuenta,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.imoCuentaDetalle
                        .replace('{idperiodo}',cuenta.join(':'))
                        .replace('{ctacodigo}',idperiodo)
                        .replace('{imoNv2}', encodeURIComponent(imoNv2));
  
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data                      
}

// MAO - PPTO

export function apiGetMaoPPTOData(year,idperiodo,empresaChoice,proyectoChoice, pdn = 0){
  let api_dir_subs = endpointPath.getMaoPPTOData
                                      .replace('{year}',year)
                                      .replace('{periodo}',idperiodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( typeof pdn !== 'undefined' ) {
    api_dir_subs =  api_dir_subs.concat('pdn=').concat(pdn).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetMaoPPTODetalleMovimientos(filter,imoNv2,imoNv3,empresaChoice,proyectoChoice){

  let api_dir_subs = endpointPath.getMaoPPTODetalleMovimientos
                                      .replace('{imoNv2}', encodeURIComponent(imoNv2))
                                      .replace('{imoNv3}', encodeURIComponent(Object.keys(imoNv3).join('|')))
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( typeof filter?.pdn !== 'undefined' ) {
    api_dir_subs =  api_dir_subs.concat('pdn=').concat(filter.pdn).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetMaoPPTODetalleCategoria(idperiodo,imoNv2,abueloidcategoria,empresaChoice,proyectoChoice,pdn = 0){
  let api_dir_subs = endpointPath.getMaoPPTODetalleCategoria
                        .replace('{idperiodo}',idperiodo)
                        .replace('{abueloidcategoria}',abueloidcategoria)
                        .replace('{imoNv2}', encodeURIComponent(imoNv2));
  
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( typeof pdn !== 'undefined' ) {
    api_dir_subs =  api_dir_subs.concat('pdn=').concat(pdn).concat("&")
  }
  const data = mainGet(endpointDomain, api_dir_subs)

  return data                      
}

export function apiGetMaoPPTOChartVentas(filter,imoNv2,imoNv3,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.getMaoPPTOChartVentas
                                      .replace('{imoNv2}', encodeURIComponent(imoNv2.join('|')))
                                      .replace('{imoNv3}', encodeURIComponent(imoNv3.join('|')))
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( typeof filter?.pdn !== 'undefined' ) {
    api_dir_subs =  api_dir_subs.concat('pdn=').concat(filter.pdn).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetMaoPPTOChartVentasByIMO3(filter,imoNv2,imoNv3,empresaChoice,proyectoChoice){
  let api_dir_subs = endpointPath.getMaoPPTOChartVentasByIMO3
                                      .replace('{imoNv2}', encodeURIComponent(imoNv2.join('|')))
                                      .replace('{imoNv3}', encodeURIComponent(imoNv3.join('|')))
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( typeof filter?.pdn !== 'undefined' ) {
    api_dir_subs =  api_dir_subs.concat('pdn=').concat(filter.pdn).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}


// // //empleados
// // function getEmpleados(pageSize, page, data = {}){
// //   return mainPost(EmpleadosUrl, `/rrhh_empleados?page_size=${pageSize}&page_num=${page}`, data)
// }

export function apiGetCuentasContablesAll(page,itemsperpage,order,search){
  let api_dir_subs = endpointPath.getMantenedorCuentasGetAll
  if ( page > 0 ) {
    api_dir_subs =  api_dir_subs.concat('page=').concat(page).concat("&")
  }
  if ( itemsperpage > 0 ) {
    api_dir_subs =  api_dir_subs.concat('itemsperpage=').concat(itemsperpage).concat("&")
  }
  if ( order.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('orderby=').concat(order[0]).concat("&")
    api_dir_subs =  api_dir_subs.concat('ordertype=').concat(order[1]?'a':'d').concat("&")
  }
  if ( search.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('search=').concat(search).concat("&")
  }
  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetcuentaGetInfo(MainAccountId){
  let api_dir_subs = endpointPath.cuentaGetInfo.replace('{MainAccountId}',MainAccountId);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiGetDiferenciaEntrePlanes(){
  const data = mainGet(endpointDomain, endpointPath.getDiferenciaEntrePlanes);
  return data;
}

export function apiGetDiferenciaEntrePlanYBalance(){
  const data = mainGet(endpointDomain, endpointPath.getDiferenciaEntrePlanYBalance);
  return data;
}

export function apiPostCuentaCreate(data) {
  return mainPost(endpointDomain, endpointPath.cuentaCreate, data)
}

export function apiPutCuentaUpdate(data){                                 
  return mainPut(endpointDomain, endpointPath.cuentaUpdate, data)
}

export function apiCommitCuenta(MainAccountId){
  let api_dir_subs = endpointPath.commitCuenta.replace('{MainAccountId}',MainAccountId);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiReCaching(builddata = false){
  let api_dir_subs = endpointPath.reCaching
  if ( builddata ) {
    api_dir_subs =  api_dir_subs.concat('builddata=').concat(builddata).concat("&")
  }
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiReCachingEERRBalanceMao(builddata = false){
  let api_dir_subs = endpointPath.reCachingEERRBalanceMao
  if ( builddata ) {
    api_dir_subs =  api_dir_subs.concat('builddata=').concat(builddata).concat("&")
  }
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}


export function apiGetLastCaching(){
  const data = mainGet(endpointDomain, endpointPath.getLastCaching);
  return data;
}

// Mantenedor de proyectos
export function apiGetMantenedorProyectosGetAll(page,itemsperpage,order,search){
  let api_dir_subs = endpointPath.getMantenedorProyectosGetAll
  if ( page > 0 ) {
    api_dir_subs =  api_dir_subs.concat('page=').concat(page).concat("&")
  }
  if ( itemsperpage > 0 ) {
    api_dir_subs =  api_dir_subs.concat('itemsperpage=').concat(itemsperpage).concat("&")
  }
  if ( order.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('orderby=').concat(order[0]).concat("&")
    api_dir_subs =  api_dir_subs.concat('ordertype=').concat(order[1]?'a':'d').concat("&")
  }
  if ( search.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('search=').concat(search).concat("&")
  }
  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetMantenedorProyectosListName(ListName){
  let api_dir_subs = endpointPath.getMantenedorProyectosListName.replace('{listname}',ListName);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiPutMantenedorProyectosListUpdate(data){                                 
  return mainPut(endpointDomain, endpointPath.putMantenedorProyectosListUpdate, data)
}


export function apiPutMantenedorProyectosUpdate(data){                                 
  return mainPut(endpointDomain, endpointPath.putMantenedorProyectosUpdate, data)
}


export function apiGetMantenedorProyectosNewFound(){
  const data = mainGet(endpointDomain, endpointPath.getMantenedorProyectosNewFound);
  return data;
}

export function apiGetMantenedorProyectosCheckNew(){
  const data = mainGet(endpointDomain, endpointPath.getMantenedorProyectosCheckNew);
  return data;
}

export function apiGetMantenedorProyectosTitleForNew(){
  const data = mainGet(endpointDomain, endpointPath.getMantenedorProyectosTitleForNew);
  return data;
}

export function apiGetMantenedorProyectosTitleForAll(){
  const data = mainGet(endpointDomain, endpointPath.getMantenedorProyectosTitleForAll);
  return data;
}

export function apiSaveMantenedorProyectosCommitNew(){
  const data = mainGet(endpointDomain, endpointPath.saveMantenedorProyectosCommitNew);
  return data;
}

export function apiGetChileDivision(){
  const data = mainGet(endpointDomain, endpointPath.getChileDivision);
  return data;
}

export function apiGetMantenedorProyectosTitleFaena(divkey){
  let api_dir_subs = endpointPath.getMantenedorProyectosTitleFaena.replace('{divkey}',divkey);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiPutMantenedorProyectosTitleMass(data){                                 
  return mainPut(endpointDomain, endpointPath.putMantenedorProyectosTitleMass, data)
}

// Mantenedor de Proyectos - Plan de Negocios

export function apiGetMantenedorProyectosPlanDeNegocioGetLista(idproyecto){
  let api_dir_subs = endpointPath.getMantenedorProyectosPlanDeNegocioGetLista.replace('{idproyecto}',idproyecto);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiGetMantenedorProyectosPlanDeNegocioGetData(idproyecto,year_plan,cuatrimestre,id_cuatrimestre){
  let api_dir_subs = endpointPath.getMantenedorProyectosPlanDeNegocioGetData
                                                                      .replace('{idproyecto}',idproyecto)
                                                                      .replace('{year_plan}',year_plan)
                                                                      .replace('{cuatrimestre}',cuatrimestre)
                                                                      .replace('{id_cuatrimestre}',id_cuatrimestre)
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiPostMantenedorProyectosPlanDeNegocio(data) {
  return mainPost(endpointDomain, endpointPath.postMantenedorProyectosPlanDeNegocio, data)
}

export function apiPutMantenedorProyectosPlanDeNegocio(data) {
  return mainPut(endpointDomain, endpointPath.putMantenedorProyectosPlanDeNegocio, data)
}

// Mantenedor de Proyectos - Informacion Presupuestados

export function apiGetMantenedorProyectosInformacionPresupuestados(idproyecto,year){
  let api_dir_subs = endpointPath.getMantenedorProyectosInformacionPresupuestados;

  if ( idproyecto !== '') {
    api_dir_subs =  api_dir_subs.concat('idproyecto=').concat(idproyecto).concat("&")
  }
  if ( year !== '') {
    api_dir_subs =  api_dir_subs.concat('year=').concat(year).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiPostMantenedorProyectosInformacionPresupuestados(data) {
  return mainPost(endpointDomain, endpointPath.postMantenedorProyectosInformacionPresupuestados, data)
}

// Mantenedor Proyectos Participaciones

export function apiGetMantenedorProyectosParticipacionesLista(idempresa,idproyecto,divkey){
  let api_dir_subs = endpointPath.getMantenedorProyectosParticipacionesLista
                                                                      .replace('{idempresa}',idempresa)
                                                                      .replace('{idproyecto}',idproyecto)
                                                                      .replace('{divkey}',divkey)
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiPostMantenedorProyectosParticipacionesInsertar(data) {
  return mainPost(endpointDomain, endpointPath.postMantenedorProyectosParticipacionesInsertar, data)
}

export function apiDeleteMantenedorProyectosParticipacionesDelete(idempresa,idproyecto,divkey,idperiodo){
  let api_dir_subs = endpointPath.deleteMantenedorProyectosParticipacionesDelete
                                                                      .replace('{idempresa}',idempresa)
                                                                      .replace('{idproyecto}',idproyecto)
                                                                      .replace('{divkey}',divkey)
                                                                      .replace('{idperiodo}',idperiodo)
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

// Cotizaciones

export function apiGetCotizaciones(fecha){
  let api_dir_subs = endpointPath.cotizaciones

  if ( typeof fecha === 'string' ) {
    api_dir_subs =  api_dir_subs.concat('fecha=').concat(fecha)
  }

  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetCotizacionesPorFechaYMoneda(moneda,fecha){
  let api_dir_subs = endpointPath.cotizacionesPorFechaYMoneda
                                    .replace('{moneda}', moneda)
                                    .replace('{fecha}', fecha);
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

// Proyectos Prespuestados

export function apiGetProyectosControlData(idperiodo,empresaChoice,proyectoChoice,column){

  let api_dir_subs = endpointPath.getProyectosControlData.replace('{idperiodo}',idperiodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }                                    
  if ( column.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('column=').concat(column[0]).concat("&")
    }
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetProyectosPresupuestosData(idperiodo,empresaChoice,proyectoChoice,plan,column){

  let api_dir_subs = endpointPath.getProyectosPresupuestosData.replace('{idperiodo}',idperiodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( column.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('plan=').concat(plan[0]).concat("&")
    }                                    
  if ( column.length > 0 ) {
  api_dir_subs =  api_dir_subs.concat('column=').concat(column[0]).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetProyectosPresupuestosList(proyectoChoice){
  let api_dir_subs = endpointPath.getProyectosPresupuestosList
  
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  
  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetproyectosPresupuestosNv2(
                                                filter,
                                                proyectosPresupuestosNv2,
                                                tipoSimplificado,
                                                empresaChoice,
                                                proyectoChoice,
                                                plan,column,
                                                nombre_proyecto = false
                                              ){
  let api_dir_subs = endpointPath.getProyectosPresupuestosNv2
                                      .replace('{proyectosPresupuestosNv2}', encodeURIComponent(proyectosPresupuestosNv2.join('|')))
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'))
                                      .replace('{tipoSimplificado}', encodeURIComponent(tipoSimplificado.join('|')));

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( plan.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('plan=').concat(plan[0]).concat("&")
    }                                    
  if ( column.length > 0 ) {
  api_dir_subs =  api_dir_subs.concat('column=').concat(column[0]).concat("&")
  }
  if ( nombre_proyecto.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('np=').concat(encodeURIComponent(nombre_proyecto.join('|'))).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetProyectosPresupuestosChart(filter,tipoSimplificado,proyectosPresupuestosNv2,clasificaciones,empresaChoice,proyectoChoice,plan){
  let api_dir_subs = endpointPath.getProyectosPresupuestosChart
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'))
                                      .replace('{tipoSimplificado}', encodeURIComponent(tipoSimplificado.join('|')))
                                      .replace('{proyectosPresupuestosNv2}', encodeURIComponent(proyectosPresupuestosNv2.join('|')))
                                      .replace('{clasificaciones}', encodeURIComponent(clasificaciones.join('|')));

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( plan.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('plan=').concat(plan[0]).concat("&")
  }                                    

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetProyectosPresupuestosChartAvanceIC(filter,proyectosPresupuestosNv2,clasificaciones,empresaChoice,proyectoChoice,plan){
  let api_dir_subs = endpointPath.getProyectosPresupuestosChartAvanceIC
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'))
                                      .replace('{proyectosPresupuestosNv2}', encodeURIComponent(proyectosPresupuestosNv2.join('|')))
                                      .replace('{clasificaciones}', encodeURIComponent(clasificaciones.join('|')));

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( plan.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('plan=').concat(plan[0]).concat("&")
  }                                    

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetProyectosPresupuestosChartResultados(filter,proyectosPresupuestosNv2,clasificaciones,empresaChoice,proyectoChoice,plan){
  let api_dir_subs = endpointPath.getProyectosPresupuestosChartResultado
                                      .replace('{idperiodo}',filter.idperiodoChoice.join('+'))
                                      .replace('{proyectosPresupuestosNv2}', encodeURIComponent(proyectosPresupuestosNv2.join('|')))
                                      .replace('{clasificaciones}', encodeURIComponent(clasificaciones.join('|')));

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( plan.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('plan=').concat(plan[0]).concat("&")
  }                                    

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

// Mantenedor Clasificacion MAO

export function apiGetMantenedorClasificacionMao(page,itemsperpage,order,search){
  let api_dir_subs = endpointPath.getMantenedorClasificacionMao
  if ( page > 0 ) {
    api_dir_subs =  api_dir_subs.concat('page=').concat(page).concat("&")
  }
  if ( itemsperpage > 0 ) {
    api_dir_subs =  api_dir_subs.concat('itemsperpage=').concat(itemsperpage).concat("&")
  }
  if ( order.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('orderby=').concat(order[0]).concat("&")
    api_dir_subs =  api_dir_subs.concat('ordertype=').concat(order[1]?'a':'d').concat("&")
  }
  if ( search.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('search=').concat(search).concat("&")
  }
  const data = mainGet(endpointDomain, api_dir_subs)
  return data
}

export function apiGetMantenedorClasificacionMaoCheckSinClasificar(option = 'all'){
  let api_dir_subs = endpointPath.getMantenedorClasificacionMaoCheckSinClasificar
                                                                      .replace('{option}',option);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiPostMantenedorClasificacionMaoSaveCategoria(data) {
  return mainPost(endpointDomain, endpointPath.postMantenedorClasificacionMaoSaveCategoria, data)
}

export function apiGetMantenedorClasificacionMaoListaDeMao() {
  const data = mainGet(endpointDomain, endpointPath.getMantenedorClasificacionMaoListaDeMao)
  return data
}

export function apiPostMantenedorClasificacionMaoSaveNewTextoClasificacion(data) {
  return mainPost(endpointDomain, endpointPath.postMantenedorClasificacionMaoSaveNewTextoClasificacion, data)
}

export function apiPutMantenedorClasificacionMaoUpdateItem(data) {
  return mainPut(endpointDomain, endpointPath.putMantenedorClasificacionMaoUpdateItem, data)
}

export function apiPostCargaExternosCreateNewProject(data) {
  return mainPost(endpointDomain, endpointPath.postCargaExternosCreateNewProject,data)
}

export function apiPostCargaExternosCreateNewProjectAndCommitMantenedor(data) {
  return mainPost(endpointDomain, endpointPath.postCargaExternosCreateNewProjectAndCommitMantenedor,data)
}

export function apiPostCargaExternosCargaDatos(data,saldosacumulados) {
  let api_dir_subs = endpointPath.postCargaExternosCargaDatos.replace('{saldosacumulados}',saldosacumulados)
  return mainPost(endpointDomain, api_dir_subs,data)
}

export function apiPostCargaExternosCargaDatosTemp(data,saldosacumulados) {
  let api_dir_subs = endpointPath.postCargaExternosCargaDatosTemp.replace('{saldosacumulados}',saldosacumulados)
  return mainPost(endpointDomain, api_dir_subs,data)
}

export function apiGetCargaExternosCargaCommitsByIdproyecto(idproyecto, idperiodo){
  let api_dir_subs = endpointPath.getCargaExternosCargaCommitsByIdproyecto
                                                                      .replace('{idproyecto}',idproyecto)
                                                                      .replace('{idperiodo}',idperiodo);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiGetCargaExternosCargaCommitData(hashid){
  let api_dir_subs = endpointPath.getCargaExternosCargaCommitData
                                                                      .replace('{hashid}',hashid);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

export function apiGetCargaExternosActualizacionDataset(hashid){
  let api_dir_subs = endpointPath.getCargaExternosActualizacionDataset
                                                                      .replace('{hashid}',hashid);
  const data = mainGet(endpointDomain, api_dir_subs);
  return data;
}

// Evolutivos

export function apiGetEvolutivoEERR(periodo,empresaChoice,proyectoChoice,cuentaNV3,cuentaChoice = [],
                                    participacion = false, soloplan = false, xf = true) {

  let api_dir_subs = endpointPath.getEvolutivoEERR
                                              .replace('{cuentaNV3}',encodeURIComponent(cuentaNV3.join('|')))
                                              .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( cuentaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentaChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan).concat("&")
  api_dir_subs =  api_dir_subs.concat('xf=').concat(xf)

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetEvolutivoDirectorios(periodo,empresaChoice,proyectoChoice,cuentaNV3,cuentaChoice = []) {

  let api_dir_subs = endpointPath.getEvolutivoDirectorios
                                              .replace('{cuentaNV3}',encodeURIComponent(cuentaNV3.join('|')))
                                              .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( cuentaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentaChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetEvolutivoBalance(periodo,empresaChoice,proyectoChoice,cuentaNV3,cuentaChoice = [], participacion = false, soloplan = false) {

  let api_dir_subs = endpointPath.getEvolutivoBalance
                                              .replace('{cuentaNV3}',encodeURIComponent(cuentaNV3.join('|')))
                                              .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( cuentaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentaChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)  

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetEvolutivoIMO(periodo,empresaChoice,proyectoChoice,imoNv2,imoNv3,cuentaChoice = []) {

  let api_dir_subs = endpointPath.getEvolutivoIMO
                                              .replace('{imoNv2}',encodeURIComponent(imoNv2.join('|')))
                                              .replace('{imoNv3}',encodeURIComponent(imoNv3.join('|')))
                                              .replace('{idperiodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( cuentaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentaChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetEvolutivoIMODesdeInicio(periodo,empresaChoice,proyectoChoice,imoNv2,imoNv3,cuentaChoice = []) {

  let api_dir_subs = endpointPath.getEvolutivoIMODesdeInicio
                                              .replace('{imoNv2}',encodeURIComponent(imoNv2.join('|')))
                                              .replace('{imoNv3}',encodeURIComponent(imoNv3.join('|')))
                                              .replace('{idperiodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( cuentaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentaChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

// Consolidado

export function apigetConsolidadoListaByNV3(periodo,empresaChoice,proyectoChoice,participacion = true,soloplan = false){

  let api_dir_subs = endpointPath.getConsolidadoListaByNV3
                                      .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetConsolidadoByNV3(nv3Choice,periodo,empresaChoice,proyectoChoice,participacion = true,soloplan = false){

  let api_dir_subs = endpointPath.getConsolidadoByNV3
                                      .replace('{clasificacionNV3}',nv3Choice.join('|'))
                                      .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetCuentasConsolidadoByN3(filter,selNv2,empresaChoice,proyectoChoice,participacion = false,soloplan = false){
  let api_dir_subs = endpointPath.getCuentasConsolidadoByN3
                                      // .replace('{redis_key}',checkSliceToken(filter.yearChoice[0]) || filter.yearChoice[0])
                                      .replace('{cuentaNV3}',Object.keys(selNv2).join('|'))
                                      .replace('{periodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)  
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetConsolidadoBalanceByNV4(nv3Choice,periodo,empresaChoice,proyectoChoice,participacion = true,soloplan = false){

  let api_dir_subs = endpointPath.getConsolidadoBalanceByNV4
                                      .replace('{clasificacionNV4}',nv3Choice.join('|'))
                                      .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetConsolidadoBalanceListaByNV4(periodo,empresaChoice,proyectoChoice,participacion = true,soloplan = false){

  let api_dir_subs = endpointPath.getConsolidadoBalanceListaByNV4
                                      .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetConsolidadoBalanceCuentas(filter,selNv2,empresaChoice,proyectoChoice,participacion = false,soloplan = false){
  let api_dir_subs = endpointPath.getConsolidadoBalanceCuentas
                                      .replace('{cuentaNV4}',Object.keys(selNv2).join('|'))
                                      .replace('{ultimo_periodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)  
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetConsolidadoBalanceCuentasSubgrupo(filter,selNv2,empresaChoice,proyectoChoice,participacion = false,soloplan = false){
  let api_dir_subs = endpointPath.getConsolidadoBalanceCuentasSubgrupo
                                      .replace('{cuentaNV4}',selNv2.join('|'))
                                      .replace('{ultimo_periodo}',filter.idperiodoChoice.join('+'));
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)  
  
  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

export function apiGetConsolidadoBalanceMontosMensuales(periodo,empresaChoice,proyectoChoice,cuentaNV3,cuentaChoice = [], participacion = false, soloplan = false) {

  let api_dir_subs = endpointPath.getConsolidadoBalanceMontosMensuales
                                              .replace('{cuentaNV3}',encodeURIComponent(cuentaNV3.join('|')))
                                              .replace('{periodo}',periodo);
  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }
  if ( cuentaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('c=').concat(cuentaChoice.join(':')).concat("&")
  }

  api_dir_subs =  api_dir_subs.concat('participacion=').concat(participacion).concat("&")
  api_dir_subs =  api_dir_subs.concat('plan=').concat(soloplan)  

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}

//Mantenedor Empresas
export function apiGetMantenedorEmpresasLista() {
  const data = mainGet(endpointDomain, endpointPath.getMantenedorEmpresasLista)
  return data
} 
export function apiPutMantenedorEmpresasUpdate(data) {
  return mainPut(endpointDomain, endpointPath.putMantenedorEmpresasUpdate, data)
} 
export function apiGetSociosLista() {
  const data = mainGet(endpointDomain, endpointPath.getSociosLista)
  return data
} 
export function apiPostSociosAdd(data) {
  return mainPost(endpointDomain, endpointPath.postSociosAdd, data)
} 

export function apiPostMantenedorEmpresasAdd(data) {
  return mainPost(endpointDomain, endpointPath.postMantenedorEmpresasAdd, data)
} 

//Reporte Mensual
export function apiGetProyectoInfo(idperiodo,empresaChoice,proyectoChoice){

  let api_dir_subs = endpointPath.getProyectoInfo.replace('{idperiodo}',idperiodo);

  if ( empresaChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('e=').concat(empresaChoice.join(':')).concat("&")
  }
  if ( proyectoChoice.length > 0 ) {
    api_dir_subs =  api_dir_subs.concat('y=').concat(proyectoChoice.join(':')).concat("&")
  }

  const data = mainGet(endpointDomain, api_dir_subs)

  return data
}
