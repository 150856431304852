export const appRoutes = {
    Root: '/',
    Login: '/login',
    protectedDash: '/dash',
    RootDefault: '/dash/eerr_indicadores',
    EERRIndicadores: 'eerr_indicadores',
    EERRDirectorios: 'eerr_directorios',    
    Balance: 'balance',        
    Imo: 'imo',            
    Inventario: 'inventario',        
    CargaExternos: 'carga_externos',     
    UserControl: 'user_control',
    MantenedorCuentas: 'mantenedor_cuentas',
    MantenedorProyectos: 'mantenedor_proyectos',     
    ProyectosPresupuestados: 'proyectos_presupuestados',        
    MantenedorClasificacionMao: 'mantenedor_clasificacion_mao',    
    ConsolidadoEERR: 'consolidado_eerr',     
    ConsolidadoBalance: 'consolidado_balance',
    ReporteMensual: 'reporte_mensual',     
  }

export const escapeAppRoutes = {
  'eerr': 'eerr_indicadores',
  'directorios': 'eerr_directorios',
  'balance_general': 'balance',
  'imo': 'imo',
  'proyectos_presupuestados': 'proyectos_presupuestados',
  'carga_externos': 'carga_externos',
  'consolidado_eerr': 'consolidado_eerr',
  'consolidado_balance': 'consolidado_balance',
  'reporte_mensual': 'reporte_mensual'
}

export function getEscapeRoute(views) {
  const firstTrueKey = Object.keys(views).find(key => views[key] === true);
  
  if (!firstTrueKey) return appRoutes.Login;

  return "/dash/" + escapeAppRoutes[firstTrueKey];
}