import React, { useState } from 'react'
import { apiLogin } from '../helpers/httpHelpers'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from '../app/routes'

import bistaLogo from "../bista-logo-blue-white.svg";

import './Login.css';
import ConectandoseAnimation from '../utils/ConectandoseAnimation';

const overlayStyle = {
  position: 'fixed',
  top: 0,
  minHeight: '100vh', 
  minWidth: '100vw',
  height: 'calc(100vh)',
  backgroundColor: 'rgba(0, 0, 0, 0.25)'
}

const initialForm = {
  username: '',
  password: '',
}

function Login() {


  const [form, setForm] = useState(initialForm)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)  
  const [intentos, setIntentos] = useState(3)
  // eslint-disable-next-line no-unused-vars
  const [fecha, setFecha] = useState('')
  const navigate = useNavigate()
//   const cookies = new Cookies()

  const postLogin = (e) => {

    e.preventDefault()
    setLoading(true);
    // console.log(form)
    apiLogin(form)
    .then(result => {
      // console.log(result)
      const token = result.data.token;
      if(result.status == 200 && token){
        // console.log(result.data)

        localStorage.clear();
        localStorage.setItem('user-token', token);
        navigate(appRoutes.RootDefault)
      } else {
        setError(true)
        setIntentos(c => c-1)
        setForm(initialForm)
      }

    })
    .finally(
      () => setLoading(false)
    );
  }

  return (
<div className="d-md-flex half">
<div className="contents">
<div className="container">
<div className="row align-items-center justify-content-center">
<div className="col-md-12">
<div className="form-block mx-auto">
<div className="text-center">
{/* <h3><strong>Besalco</strong></h3> */}
<div className="logo_wrapper2">
    <div className="q_logo">
        <a itemProp="url" href="https://bscbi.cl/" style={{height: '70px', visibility: 'visible'}}>
            <img itemProp="image" className="normal" src={bistaLogo} alt="branding-blue" style={{height: "100%"}} />
        </a>
    </div>
</div>  
</div>
<form action="#" onSubmit={e => postLogin(e)}>
<ConectandoseAnimation state={loading}>
<div className="form-group first">
<label id="usuario">Usuario</label>
<input type="text" className="form-control" value={form.username} placeholder="id de usuario" id="username" onChange={(e) => setForm({...form, username: e.target.value})}/>
</div>
<div className="form-group last mb-3">
<label id="contraseña">Contraseña</label>
<input type="password" className="form-control" value={form.password} placeholder="contraseña" id="password" onChange={(e) => setForm({...form, password: e.target.value})}/>
</div>
<div className="d-sm-flex mb-5 align-items-center">
{error && <p className='text-danger'>Usuario y/o contraseña incorrectos</p>}    
{/* <label className="control control--checkbox mb-3 mb-sm-0"><span className="caption">Remember me</span>
<input type="checkbox" checked="checked">
<div className="control__indicator"></div>
</label>
<span className="ml-auto"><a href="#" className="forgot-pass">Forgot Password</a></span> */}
</div>
<input type="submit" value="Ingresar" className="btn btn-block btn-warning" />
</ConectandoseAnimation>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
  )
}

export default Login
