import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import 'bootswatch/dist/zephyr/bootstrap.min.css';
import './index.css'

import { appRoutes } from './app/routes'
import Login from './pages/Login'
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './utils/ErrorPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <BrowserRouter>
        <Routes>
          <Route path={appRoutes.Login} element={<Login />} />
          <Route path={'/*'} element={<App />} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
