import React, { useEffect, useState } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { apiCheckUserLogged } from "../helpers/httpHelpers";
import { appRoutes } from "../app/routes";

function ProtectedRoute () {
    // const navigate = useNavigate();
    // const [isLoggedIn, setIsLoggedIn] = useState(false);

    let isLoggedIn = apiCheckUserLogged()

    if (!isLoggedIn) {
        return <Navigate to={appRoutes.Login} />;
    }

    return <Outlet />
}
export default ProtectedRoute;