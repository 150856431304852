import React from 'react';

// import imageLogo from "../besalco-construcciones-blue.svg";
import bistaLogo from "../bista-logo-blue-white.svg";
import { apiLogoutNoRefresh } from '../helpers/httpHelpers';

function ErrorPage({error}) {
  console.log(error)
  
  apiLogoutNoRefresh()

  return (
  <div className="container">
        <div className="d-flex justify-content-center align-items-center h-100" style={{minHeight: "100vh"}}>
        <div className="vstack gap-2 justify-content-center align-items-center">
            <img itemProp="image" className="normal" src={bistaLogo} alt="Logo" style={{height: "100px"}} />  
            <h5 className="mt-5" style={{color: "var(--besalco-blue)"}}>
              Ha ocurrido un error.
            </h5>
            <h5 style={{color: "var(--besalco-blue)"}}>
              Por favor, intente refrescar la página en unos momentos.
            </h5>
        </div>
        </div>
    </div>
  )
}

export default ErrorPage;