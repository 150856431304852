import React from 'react';
import { ProgressBar as ProgressBarSpinner } from 'react-loader-spinner';

// import imageLogo from "../besalco-construcciones-blue.svg";
import bistaLogo from "../bista-logo-blue-white.svg";

function LoadingBesalco() {
  return (
  <div className="container">
        <div className="d-flex justify-content-center align-items-center h-100" style={{minHeight: "100vh"}}>
        <div className="vstack gap-2 justify-content-center align-items-center">
            <img itemProp="image" className="normal" src={bistaLogo} alt="Logo" style={{height: "100px"}} />  
            <ProgressBarSpinner
            height="100"
            width="600"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor = '#003c7d'
            barColor = '#003c7d'
            />
        </div>
        </div>
    </div>
  )
}

export default LoadingBesalco;