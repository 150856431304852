import { Suspense, lazy } from 'react';
import { Route, Routes} from 'react-router-dom';
import { appRoutes } from './app/routes'
import LoadingBesalco from './utils/LoadingBesalco';

import ProtectedRoute from './utils/Protectroute';

// Not found routes defaults to Dashboard
const RootDefault = lazy(() => import( './utils/Rootdefaults'));

// Everything else
const Dashboard = lazy(() => import('./layouts/Dashboard.js'));
const Directorios = lazy(() => import('./layouts/Directorios'));
const Balance = lazy(() => import('./layouts/Balance'));
const Inventario = lazy(() => import('./layouts/Inventario'));
const Imo = lazy(() => import('./layouts/Imo'));
const ProyectosPresupuestados = lazy(() => import('./layouts/ProyectosPresupuestados'));
const ConsolidadoEERR = lazy(() => import('./layouts/ConsolidadoEERR'));
const ConsolidadoBalance = lazy(() => import('./layouts/ConsolidadoBalance'));
const ReporteMensual = lazy(() => import('./layouts/ReporteMensual'));

// Carga de datos
const CargaExternos = lazy(() => import('./layouts/CargaExternos'));

// This needs admin check
const UserControl = lazy(() => import('./layouts/UserControl'));
const MantenedorCuentas = lazy(() => import('./layouts/MantenedorCuentas'));
const MantenedorProyectos = lazy(() => import('./layouts/MantenedorProyectos'));
const MantenedorClasificacionMao = lazy(() => import('./layouts/MantenedorClasificacionMao'));

// Without Lazy
// import Dashboard from './layouts/Dashboard.js';
// import RootDefault from './utils/Rootdefaults';
// import NotFoundDefault from './utils/Notfound';
// import UserControl from './layouts/UserControl';
// import Directorios from './layouts/Directorios';
// import Balance from './layouts/Balance';
// import Imo from './layouts/Imo';


// In case Not found wanted
// const NotFoundDefault = lazy(() => delayAni(import('./utils/Notfound')));

// function delayAni(promise) {
//     return new Promise(resolve => {
//       setTimeout(resolve, 1000);
//     }).then(() => promise);
// }

function View(child) {
    return (
        <Suspense fallback={<LoadingBesalco />}>
            {child}
        </Suspense>
    )
}

function App() {
  return (
        // <Router>
            <Routes>
                {/* <Route exact path={appRoutes.Root} element={View(<RootDefault/>)} /> */}
                <Route path={appRoutes.protectedDash} element={<ProtectedRoute />}>
                    <Route exact path={appRoutes.EERRIndicadores} element={View(<Dashboard/>)} />
                    <Route exact path={appRoutes.EERRDirectorios} element={View(<Directorios/>)} />
                    <Route exact path={appRoutes.Balance} element={View(<Balance/>)} />     
                    <Route exact path={appRoutes.Inventario} element={View(<Inventario/>)} />     
                    <Route exact path={appRoutes.Imo} element={View(<Imo/>)} />     
                    <Route exact path={appRoutes.CargaExternos} element={View(<CargaExternos/>)} />     
                    <Route exact path={appRoutes.UserControl} element={View(<UserControl/>)} />       
                    <Route exact path={appRoutes.MantenedorCuentas} element={View(<MantenedorCuentas/>)} /> 
                    <Route exact path={appRoutes.MantenedorProyectos} element={View(<MantenedorProyectos/>)} />   
                    <Route exact path={appRoutes.ProyectosPresupuestados} element={View(<ProyectosPresupuestados/>)} />   
                    <Route exact path={appRoutes.MantenedorClasificacionMao} element={View(<MantenedorClasificacionMao/>)} /> 
                    <Route exact path={appRoutes.ConsolidadoEERR} element={View(<ConsolidadoEERR/>)} />                
                    <Route exact path={appRoutes.ConsolidadoBalance} element={View(<ConsolidadoBalance/>)} />             
                    <Route exact path={appRoutes.ReporteMensual} element={View(<ReporteMensual/>)} />      
                </Route>
                <Route exact path='/*' element={View(<RootDefault/>)} />
            </Routes>  
        // </Router>
    )
}

export default App;